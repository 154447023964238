<!-- 客户管理 -- 往届生续费 -- 列表 -->
<template>
  <div>
    <list-template
        :loading="loading"
        :search-config="searchConfig"
        :is-check-input-box="false"
        @onSearch="onSearch"
        :isDownload="true"
        @onExportData="onDownload"
        :table-config="tableConfig"
        :table-data="tableData"
        :total="total"
        :current-page="page"
        @onChangePage="handleCurrentChange"
        @onHandle="handleEdit"
    ></list-template>
  </div>
</template>

<script>
import {mapState} from "vuex";
import tools from "@/utils/tools";
import ListTemplate from "@/components/pages/List.vue"
import { setConfigOption } from '@/utils'
import { get_2025pack } from '@/api'

export default {
	_config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
  components: {ListTemplate},
  data() {
    return {
      loading: true,
      search: {},
      searchConfig: [
        {
          prop: "student",
          placeholder: "搜索学生姓名",
        },
        {
          prop: "teacher",
          placeholder: "搜索当前客户归属招生老师",
        },
        {
          prop: 'status',
          tag: "select",
          placeholder: '筛选审批状态',
          options: [
            {value: 1, label: '待审批'},
            {value: 2, label: '已通过'},
            {value: 3, label: '已驳回'},
          ],
        },
        {
          prop: 'school_id',
          tag: "select",
          placeholder: '筛选校区',
          options: [],
          label: "school_name",
          value: "id"
        },
        {
          prop: 'order_type',
          tag: "select",
          placeholder: '筛选订单类型',
          options: [
            { value: 1, label: '预报名' },
            { value: 2, label: '正式报名' },
            { value: 3, label: '高二高三续费' },
            { value: 4, label: '复读生正式报名' },
            { value: 5, label: '保过订单' }
          ],
        },
        {
          prop: 'pack_type',
          tag: 'select',
          placeholder: '筛选套餐类型',
          options: []
        }
      ],
      tableData: [],
      //总条数
      total: 0,
      pageCount: 0,
      tableConfig: [
        {
          prop: "status", label: "审批状态",
          render(row) {
            return `<div style="color: ${Number(row?.status) === 1 ? '#F6AD3C' : Number(row?.status) === 2 ? '#23C343' : '#F53F3F'}">${Number(row?.status) === 1 ? '待审批' : Number(row?.status) === 2 ? '已通过' : '已驳回'}</div>`
          }
        },
        {prop: "order_type_name", label: "订单类型"},
        {prop: "pack_type_name", label: "套餐类型"},
        {prop: "student_name", label: "学生姓名"},
        {prop: "school", label: "入账学校"},
        {prop: "belong_teacher", label: "当前客户归属招生老师"},
        {prop: "money_answer", label: "应缴金额"},
        {prop: "money_actual", label: "实缴金额"},
        {prop: "created_at", label: "订单创建时间"},
        {
          prop: "handle",
          label: "操作",
          width: "120rem",
          handle: true,
          render(row) {
            return Number(row?.status) === 1 ? ["审批"] : ['查看']
          }
        },
      ],
    }
  },
  created() {
    this.$store.commit("setPage", 1);
    this.getData()
    this.$_axios('/site/school').then(res => {
      this.searchConfig[3].options = res.data.data || []
    })
    this.getConfig()
  },
  activated() {
    this.getData()
  },
  computed: {
    ...mapState(["page"])
  },
  methods: {
    getConfig() {
      get_2025pack().then(res => {
        setConfigOption({ list: this.searchConfig , data: res, key: 'pack_type' })
      })
    },
    getData() {
      this.loading = true
      let params = {...this.search, page: this.page}
      this.tableData = [];
      this.$_register('/api/recruit-v2/order-offline/list', {params}).then(res => {
        let data = res.data.data
        this.tableData = data.list
        this.total = data.page.total
      }).finally(() => {
        this.loading = false
      })
    },

    onSearch(val) {
      this.$store.commit('setPage', 1);
      this.search = val;
      this.getData(val)
    },

    handleEdit(row, text) {
      switch (text.type) {
        case 'approve':
          this.$router.push('./edit?id=' + row.id)
          break;
        case 'view':
          this.$router.push('./look?id=' + row.id)
          break;
      }
    },

    // 当前页改变触发
    handleCurrentChange(val) {
      //当前页赋值给currentPage
      this.$store.commit("setPage", val);
      //拉取数据
      this.getData()
    },

    onDownload() {
      let params = {...this.search, page: this.page}
      this.$_register('api/recruit-v2/order-offline/export', {params}).then(res => {
        tools.download('线下支付审批', res.data)
      })
    }

  },
}
</script>

<style lang="scss" scoped>
::v-deep .t-search-block .el-input {

}

.role-list {
  padding-top: 24rem;
}

.button {
  margin-left: 34rem;
  margin-bottom: 30rem;
  box-sizing: border-box;
  width: 90rem;
  height: 34rem;
  opacity: 1;
  border-radius: 4rem;
}

.table {
  margin-left: 32rem;
}


::v-deep .el-table--enable-row-transition .el-table__body td.el-table__cell {
  text-align: left;
  font-size: 14rem;
  font-weight: normal;
  height: 48rem;
  box-sizing: border-box;
  color: #333333;
}

.header {
  position: fixed;
  top: 120rem;
  left: 300rem;
  font-size: 16rem;
  font-weight: bold;
  line-height: 24rem;
  color: #333333;
  letter-spacing: 3.5rem;
}

.el-button--text {
  margin-right: 40rem;
  font-size: 14rem;
  font-weight: normal;
  color: #3491fa;
}
</style>
